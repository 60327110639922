import {deepClone} from './common'
import { isEmpty } from 'j4tools'

function init (formInstance: any, data?: any) {
  // 清空 data
  if (isEmpty(data)) {
    formInstance.data = deepClone(formInstance.default)
  } else {
    formInstance.data = data
  }

}

export default {
  init
}

